import Seo from "../components/Seo";
import * as React from "react";
import { Layout } from '../components/Layout';
import { Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const ContactPage = () => {
  return (
    <Layout
      seo={
        <Seo
          title="Výstavy | MgA. Jan Hlavatý"
          keywords={["akryl na plátně", "výtvarné umění", "MgA Jan Hlavatý"]}
          description=""
        />
      }
    >
      <Grid container justifyContent="center">
        <Grid item xs={8} sx={{}}>
          <p>
            narozený roku 1946 v Praze.
            <br />
            Věnuje se volné malbě, která v současné době vyjadřuje scénografické
            vidění.
            <br />
            Motivy transformované skutečnosti mají často podobu znakové
            symboliky.
          </p>
          <p>
            Profesí je televizní, filmový a divadelní scénograf. Jeho scénické
            návrhy vycházejí vždy ze záměru vystihnout téma a smysl zadání.
          </p>
          <p>
            Studoval pražskou Výtvarnou školu na Hollarově náměstí v letech 1961
            – 1965.
          </p>
          <p>
            Následovalo studium na grafické přípravce VŠUP u profesora Josefa
            Nováka v období 1965 – 1967 a pokračování na katedře scénografie
            pražské DAMU u profesora arch. Františka Tröstera a profesora
            Ladislava Vychodila v letech 1967 – 1971.
          </p>
          <p>
            Souběžně s individuální tvorbou se Jan Hlavatý věnuje pedagogické
            činnosti ve výtvarném oboru.
          </p>
          <p>
            Jan Hlavatý je členem Syndikátu výtvarných umělců, Asociace užité
            grafiky, Společnosti Františka Tröstera.
          </p>
        </Grid>
        <Grid item xs={2} sx={{}}>
          <StaticImage
            src="../assets/images/jan.jpg"
            placeholder="blurred"
            style={{}}
            objectFit="cover"
          />
        </Grid>
        <Grid item xs={5}>
          <h2>Odborná činnost</h2>
          <p>
            1961 – 1965 Výtvarná škola Praha <br />
            1965 – 1967 VŠUP grafická přípravka prof. Josefa Nováka
            <br />
            1967 – 1971 DAMU katedra scénografie prof. arch. Františka Tröstera
            a Ladislava Vychodila
            <br />
            Od roku 1971 scénografické práce pro ČST, Krátký film Praha a
            divadelní scény.
            <br />
            1990 – 1992 zástupce profesního vedoucího výtvarného oddělení ČST
            <br />
            1992 – 1994 profesní vedoucí výtvarného oddělení ČST, později ČT
            <br />
            Od roku 1994 výtvarný pedagog
            <br />
          </p>

          <h2>Výstavy</h2>
          <p>
            1969 – Mezinárodní výstava scénografických škol – Bratislava
            <br />
            1978 – Výstava scénografie – Hodonín
            <br />
            1979 – samostatná výstava – Klicperovo divadlo Praha
            <br />
            1979 – Pražské Quadriennale
            <br />
            1982 – Výstava televizní scénografie – Praha
            <br />
            1983 – Pražské Quadriennale
            <br />
            1988 – Pražský salón volného výtvarnictví
            <br />
            1989 – Pražský salón užitého výtvarnictví
            <br />
            1991 – stáž elektronické grafiky ve společnosti CIRNEA, Paříž,
            Montreuil
            <br />
            1992 – Mezinárodní výstava televizního výtvarnictví při MTF Praha a
            její organizace
            <br />
            1993 – grafická úprava české expozice na Mezinárodním televizním
            festivalu MIPCOM v Cannes, Francie
            <br />
            1994 – Foltýnová, Křížková, Hlavatý – Galerie Chodovská tvrz Praha
            <br />
            1994 – stálá výstava televizní scénografie – foyer ČT KH Praha
            <br />
            1994 – Foltýnová, Křížková, Hlavatý – Městská galerie Šluknov
            <br />
            1996 – realizovaný návrh kašny pro pěší zónu v Kolíně
            <br />
            1998 – Křížková, Křížek, Hlavatý – Obrazy – scénografie – Městské
            muzeum, Storchova síň Hořice
            <br />
            2002 – Přítomný čas – obrazy – Lázeňský dům Lázně Bělohrad
            <br />
            2004 – Křížková, Milichovská, Hlavatý – Scénografie a volná tvorba –
            Státní okresní archiv Semily
            <br />
            2004 – Sdělení – obrazy – Galerie U lávky Praha, Troja
            <br />
            2005 – Scénografie – Městské divadlo Mladá Boleslav
            <br />
            2005 – Salón české scénografie – Lobkovický palác Praha
            <br />
            2005 – Obrazy, plakáty, scénografie – Altán Klamovka Praha
            <br />
          </p>
        </Grid>
        <Grid item xs={5}>
          <p>
            2006 – Malby, scénografie – Ateliér FUUD UJEP Ústí n.L., Dubí
            <br />
            2008 – Mail & design – grafické biennale Praha – Altán Klamovka
            Praha
            <br />
            2009 – Výstava přátel a kolegů Miloslava Hilského – Chvalský zámek
            Horní Počernice
            <br />
            2010 – Malby, scénografie – Městské museum a galerie Lomnice n.
            Popelkou
            <br />
            2010 – Paříž v Praze – Claire Aucouturier, Elisabeth Schubert, Jan
            Hlavatý – Galerie S.V.U. Mánes Diamant Praha
            <br />
            2010 – Diskriminace – grafické biennale – Altán Klamovka Praha
            <br />
            2011 – Pražské Quadriennale
            <br />
            2011 – Otisky – výstava Nového sdružení – Galerie Orbis Pictus –
            Památník Vojna u Příbrami
            <br />
            2011 – China – grafické biennale – Altán Klamovka Praha
            <br />
            2012 – Výstava Nového sdružení pražských umělců – zámek Všeradice
            <br />
            2012 – Výstava Nového sdružení pražských umělců – Stará radnice
            Tábor
            <br />
            2013 – Inspirace dramatem – Galerie v zahradě – Kolín
            <br />
            2013 – Nové sdružení na Chvalském zámku – Chvaly, Horní Počernice
            <br />
            2013 – Barvy Žižkova – výstava Nového sdružení – Galerie NS Praha
            <br />
            2013 – My spolužáci VŠUP 1965 – Galerie Kruh Ostrava
            <br />
            2013 – Světy možného – Hlavatý, Tošovský – Galerie NS Praha
            <br />
            2014 – Výstava architektů, malířů a scénografů – Infocentrum
            Kolovraty
            <br />
            2014 – Nečekaná výstava – výstava Nového sdružení – Galerie NS Praha
            <br />
            2014 – Žižkov – pražský Montmartre – výstava NS – Výstavní síň
            Antonína Navrátila
            <br />
            2014 – Rehabilitace lina – výstava ročníku VŠUP 1965 – Galerie NS
            Praha
            <br />
            2014 – Pocta JUDr. Navrátilovi , společná výstava NS v Parlamentu ČR
            <br />
            2014 – Information War – grafické biennale – Altán Klamovka Praha
            <br />
            2015 – Scénické inspirace – společná výstava Klicperovo divadlo
            Hradec Králové
            <br />
            2015 – Před oponou za oponou – společná výstava Městské divadlo
            Poděbrady
            <br />
            2016 - Ozvěny scény - společná výstava Muzeum Českého ráje Turnov
            <br />
            2016 – Žáci profesora Tröstera – SUPŠ Bechyně
            <br />
            2017 – Jan Hlavatý - Krajinné představy – Velké Přílepy
            <br />
            2017 – Cesty 2017 – společná výstava Východočeské divadlo Pardubice
            <br />
            2017 – My 1965 – výstava ročníku VŠUP – Galerie Sboru českých bratří
            – Mladá Boleslav
            <br />
            2018 – A + A galerie Cyklus 30 x 30, téma Naděje - Dům u
            Černohorských, Praha
            <br />
            2018 – Grafické bienále, téma Fake News – Altán Klamovka, Praha
            <br />
            2019 – My spolužáci – Galerie Sboru českých bratří - Mladá Boleslav
          </p>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ContactPage;
